import { Directive, ElementRef, inject, input, OnInit } from '@angular/core';

type ButtonSize = 'normal' | 'large';

const baseClasses = 'whitespace-nowrap inline-flex justify-center items-center cursor-pointer transition-colors duration-100 ease-in-out';


const Variants: {attribute: string; classes: (size?: ButtonSize) => string}[] = [
  {
    attribute: 'btn-primary',
    classes: (size) => `${baseClasses} ${size === 'normal' ? 'px-4 py-2' : 'px-5 py-4'} rounded-[60px] bg-black text-white disabled:bg-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed`
  },
  {
    attribute: 'btn-secondary',
    classes: (size) => `${baseClasses} ${size === 'normal' ? 'px-4 py-2' : 'px-5 py-4'} rounded-[60px] border border-1 border-black text-black disabled:border-gray-200 disabled:text-gray-600 disabled:cursor-not-allowed`
  },
  {
    attribute: 'btn-plain',
    classes: () => `${baseClasses} text-link hover:text-link-hover`
  },
  {
    attribute: 'btn-icon',
    classes: () => `${baseClasses} text-black`
  }
];

@Directive({
  selector: 'button[btn-primary], button[btn-secondary], button[btn-plain], a[btn-primary], a[btn-secondary], a[btn-plain], button[btn-icon], a[btn-icon], label[btn-primary]',
  standalone: true,
  host: {
    class: baseClasses
  }
})
export class ButtonDirective implements OnInit {
  btnSize = input<ButtonSize>('normal');

  private readonly elementRef = inject(ElementRef);

  public ngOnInit() {
    const element = this.elementRef.nativeElement;
    const classList = (element as HTMLElement).classList;

    for (const {attribute, classes} of Variants) {
      if (element.hasAttribute(attribute)) {
        classList.add(...classes(this.btnSize()).split(' '));
      }
    }
  }
}
